<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-lg-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link
              :to="{
                name: 'trainer-profile',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Trainer Profile Detail</h4>
        </div>

        <place-holder v-if="loading"></place-holder>
        <div class="card" v-if="!loading">
          <div class="card-body">
            <div class="border-bottom border-5 border-info">
              <div class="d-flex col-md-12">
                <div class="col-md-3 text-center">
                  <img
                    class="image-profile"
                    :src="trainer_profile.image_url"
                    alt=""
                  />
                </div>
                <div class="detail-info col-md-7 mb-3">
                  <p class="fs-3 mb-0 fw-bold text-uppercase">
                    <i class="mdi mdi-account-outline mdi-24px me-4"></i>
                    {{ trainer_profile.name }}
                  </p>
                  <p class="fs-5 mb-0">
                    <i class="mdi mdi-gender-male-female mdi-24px me-4"></i>
                    {{ trainer_profile.gender }}
                  </p>
                  <p class="fs-5 mb-0">
                    <i class="mdi mdi-image-filter-frames mdi-24px me-4"></i>
                    {{ trainer_profile.position_name }}
                  </p>
                  <p class="fs-5 mb-0">
                    <i class="mdi mdi-map-marker-radius mdi-24px me-4"></i>
                    {{ trainer_profile.department_name }}
                  </p>
                  <p class="fs-5 mb-0">
                    <i class="mdi mdi-email-outline mdi-24px me-4"></i>
                    {{ trainer_profile.email }}
                  </p>
                </div>
                <div class="col-md-2 mt-2">
                  <img
                    class="logo-image"
                    src="../../assets/images/umg_logo.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="shadow-lg p-3 my-3 rounded">
                  <div class="d-flex">
                    <i class="mdi mdi-history mdi-24px me-1"></i>
                    <p
                      class="fs-4 fw-bold border-bottom border-5 border-info mt-1"
                    >
                      Experience
                    </p>
                  </div>
                  <p v-html="formatValue(trainer_profile.experience)"></p>
                </div>

                <div class="shadow-lg p-3 my-3 rounded">
                  <div class="d-flex">
                    <i class="mdi mdi-school mdi-24px me-1"></i>
                    <p
                      class="fs-4 fw-bold border-bottom border-5 border-info mt-1"
                    >
                      Education and Qualification
                    </p>
                  </div>
                  <p v-html="formatValue(trainer_profile.education)"></p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="shadow-lg p-3 my-3 rounded">
                  <div class="d-flex">
                    <i class="mdi mdi-lightbulb-on-outline mdi-24px me-1"></i>
                    <p
                      class="fs-4 fw-bold border-bottom border-5 border-info mt-1"
                    >
                      Modules
                    </p>
                  </div>
                  <div
                    class="shadow-lg p-2 mt-2 rounded"
                    v-for="(item, index) in trainer_profile.modules"
                    :key="index"
                  >
                    <div class="d-flex">
                      <img
                        class="me-4 course-image"
                        :src="item.image_url"
                        alt=""
                      />
                      <span class="w-75 align-self-center">{{
                        item.name
                      }}</span>
                      <!-- <span class="w-25 align-self-center">Level</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  components: {},
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      trainer_profile: {
        gender: "",
        email: "",
        position_name: "",
        department_name: "",
      },
      filterEmployee: [],
      employees: this.$store.getters["odoo/getAllEmployees"],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getTrainerById() {
      this.loading = true;
      axios
        .get(`${this.baseUrl}admin/v1/trainer-profile/${this.$route.params.id}`)
        .then((response) => {
          this.trainer_profile = response.data.data;
          this.filterEmployee = this.employees.filter((employee) => {
            if (employee.emp_id == this.trainer_profile.employee_id) {
              return employee;
            }
          });
          this.trainer_profile.email = this.filterEmployee[0].email;
          this.trainer_profile.gender = this.filterEmployee[0].gender;
          this.trainer_profile.position_name =
            this.filterEmployee[0].position_level[0].name;
          this.trainer_profile.department_name =
            this.filterEmployee[0].department[0].name;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Trainer!");
        });
    },
    formatValue(value) {
      return value.replace(/\r\n/g, "<br>");
    },
  },
  created() {
    this.getTrainerById();
  },
};
</script>

<style></style>
<style scoped>
img.image-profile {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 1px solid #ddd;
}

img.logo-image {
  width: 160px;
  height: 160px;
}

img.course-image {
  width: 60px;
  height: 60px;
}
</style>
